import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return <div id="footer">©2020 Purgatory Resort. All Rights Reserved.</div>;
  }
}

export default Footer;
