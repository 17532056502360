import {
  faChevronDown,
  faChevronRight,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component, Fragment, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import CardSwipe from "../../Utility/CardReader";
import CartExpirationDialog from "../../Utility/CartExpirationDialog";
import { storage } from "../../Utility/CartStorage";
import {
  cryptoJS,
  formatPrice,
  roundedGiftCardAmount,
} from "../../Utility/Common";
import {
  CartItemKey,
  ExpiryCheck_IntervalMinutes,
  ItemTreeRoot,
  KIOSKSetting,
  OrderAgainNumber,
  OrderDetails,
  PassInfoKey,
  Required_CryptoSecurity,
  RestaurantDetailsByNodeId,
  SMSInfoKey,
  TableNumber,
  MaximumNumberOfPayment,
  PaymentType,
} from "../../Utility/Config";
import { get, post } from "../../Utility/HttpService";
import Spinner from "../../Utility/Spinner";
import { Storage as _storage } from "../../Utility/Storage";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";
import {
  getCardDetailsByCardId,
  getCardIdFromGuestNumber,
  getGiftCardInformation,
  getGiftCardRanges,
  getGiftCardsByGuestNumber,
  getPickupTime,
  getRestaurantTableNumbers,
  getSettingInfo,
  processSale,
  token,
  verifyProcessSale,
} from "../../Utility/URLService";
import GiftCardDialog from "../GiftCard/GiftCard";
import Header from "../Header/NavigationHeader";
import QuantityInfoDialog from "../QuantityInfo/QuantityInfo";

const QueueToolTip = (state) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <span ref={target} onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>

      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {state.restaurantDetails.rfndCondition}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

class Checkout extends Component {
  state = {};
  constructor(props) {
    super(props);
    let passInfo = this.checkAndGetPassInfo();
    this.state = {
      pickupTime: "",
      restaurantLocation: "",
      faChevronDown: true,
      isNextClicked: false,
      isPaymentInfoShowing: false,
      isPaymentInfoShowingWithKiosk: false,
      navigateFrom: "checkout",
      heading: "place order",
      count: 0,
      hideCart: true,
      closeIcon: false,
      cartItems: JSON.parse(this.getItemFromStorage(CartItemKey)),
      showSwipeCard: true,
      showCreditCardInfo: false,
      showGiftCardInfo: false,
      isShowRestaurantWaitingTime: false,
      restaurantWaitingTimeMessage: window.ENV.IsShowRestaurantWaitingTime,
      isShowSpecialRequest: false,
      specialRequest: "",
      isRestaurantPayAtPickup: false,
      giftCardInfo: {
        dialogOpen: false,
        giftCards: [],
      },
      submitOrderButtonDisabled: true,
      passInfo: passInfo,
      guestGiftCards: [],
      cardDetails: {
        id: "",
        cardNumber: "",
        cvc: "",
        expiration: "",
        amount: 0,
      },
      restaurantDetails: JSON.parse(
        _storage.getItem(RestaurantDetailsByNodeId)
      ),
      itemsExpirationModal: {
        visible: false,
        onCloseEventCallBack: this.itemsExpirationModalCloseCallBack,
        message: "",
      },
      spinner: {
        show: false,
      },
      Modal: {
        visible: false,
        cartRemoval: false,
        closeEventCallBack: this.expirationModalCloseCallback,
      },
      deliverToTable: null,
      tableNumber: 0,
      tableNumbers: [],
      isSMSEnabled: true,
      isPayAtPickupEnabled: false,
      smsConditionInfo: "",
      kioskSetting: JSON.parse(_storage.getItem(KIOSKSetting)),
      paymentType: _storage.getItem(PaymentType),
      zipCode: "",
      screenRefreshTime: 0,
      isRefreshScreen: false,
      toaster: [],
      isCardSwiped: false,
      isEmailOptionalInKiosk: true,
      giftCardRanges: [],
      cardOnFile: {
        isShowCardOnFile: false,
        isCardOnFileSelected: false,
        cardId: "",
      },
      isEmailIdDisable: false,
      isRestaurantContainServiceCharge: this.getRestaurantInfo(
        "isRestaurantContainServiceCharge"
      ),
      serviceChargeName: this.getRestaurantInfo("serviceChargeName"),
      targetQty: this.getRestaurantInfo("targetQty"),
      isCardInfoIsMasked: false,
      cardId: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validated = this.validated.bind(this);
    CardSwipe.init({
      success: this.handleSwipeSuccess,
      debug: false,
    });
    this.cvvInputRef = React.createRef();
  }

  getRestaurantInfo = (key) => {
    const restaurantInfo = storage.getRestaurantInfo();
    return restaurantInfo[key];
  };

  handleSwipeSuccess = (swipeData) => {
    let { giftCardRanges } = this.state;
    let cardData = "";
    let isAmexCard = false;

    let isGiftcard = false;
    let isCard = swipeData && swipeData.line1;
    let giftCardNo = "";
    if (isCard) {
      //let cardNo = isCard.slice(2, 8);
      let cardNo = isCard.replace(/[^\d]/g, "");
      isGiftcard = giftCardRanges.some((a) => a === cardNo.slice(0, 6));
      giftCardNo = isGiftcard ? cardNo : "";
    }
    if (isGiftcard) {
      this.getGiftCardNumber(giftCardNo);
    } else {
      if (swipeData && swipeData.line1) cardData = swipeData.line1;
      else if (swipeData && swipeData.account) {
        cardData = `${swipeData.account}${swipeData.expYear}${swipeData.expMonth}^${swipeData.lastName}/${swipeData.firstName}^`;
        isAmexCard = swipeData.type === "amex";
      }
      cardData && this.getCardNumber(cardData, isAmexCard);
    }
  };
  getGiftCardNumber = (giftCardData) => {
    let giftCardNumber = "";
    let giftCardPin = "";
    if (giftCardData && giftCardData.length === 20) {
      //let cardNumber = giftCardData.split("%B");
      giftCardNumber = giftCardData.slice(0, 16);
      giftCardPin = giftCardData.slice(16);
      if (giftCardNumber && giftCardPin) {
        let giftCardRequest = {
          cardNumber: giftCardNumber,
          pin: giftCardPin,
        };
        this.getGiftCardInformation(giftCardRequest);
      } else {
        this.showToasterMessage("Please enter valid gift card.", "w");
      }
    } else {
      this.showToasterMessage("Please enter valid gift card.", "w");
    }
  };

  getCardNumber = (cardData, isAmexCard) => {
    let { cardDetails, isCardSwiped, passInfo } = this.state;
    let value = cardData && cardData.replace(/[^\d]/g, "");
    let isCardExpired = false;
    //Condition is to check card details contains card holder name
    if (cardData && cardData.includes("^")) {
      let cardHolderDetails = cardData.substring(
        cardData.indexOf("^") + 1,
        cardData.lastIndexOf("^")
      );
      if (cardHolderDetails) {
        let personDetails = cardHolderDetails.split("/");
        if (personDetails[1]) {
          passInfo.firstName = personDetails[1]
            ? personDetails[1]
            : passInfo?.firstName;
          passInfo.lastName = personDetails[0]
            ? personDetails[0]
            : passInfo?.lastName;
        } else {
          passInfo.firstName = personDetails[0]
            ? personDetails[0]
            : passInfo?.firstName;
          passInfo.lastName = "";
        }
      }
    }
    if (isAmexCard) {
      if (value && value.length >= 18) {
        cardDetails.cardNumber = this.cardNumberFormat(value.slice(0, 15));
        let expirationValue = value.slice(15);
        let expValue = `${expirationValue[2]}${expirationValue[3]}/${expirationValue[0]}${expirationValue[1]}`;
        isCardExpired = this.checkCardExpiry(expValue);
        if (!isCardExpired) {
          cardDetails.expiration = expValue;
        }
        isCardSwiped = true;
      } else {
        this.showToasterMessage("Invalid Card", "w");
      }
    } else {
      if (value && value.length >= 20) {
        cardDetails.cardNumber = this.cardNumberFormat(value.slice(0, 16));
        let expirationValue = value.slice(16);
        let expValue = `${expirationValue[2]}${expirationValue[3]}/${expirationValue[0]}${expirationValue[1]}`;
        isCardExpired = this.checkCardExpiry(expValue);
        if (!isCardExpired) {
          cardDetails.expiration = expValue;
        }
        isCardSwiped = true;
      } else {
        this.showToasterMessage("Invalid Card", "w");
      }
    }
    if (isCardExpired) {
      this.showToasterMessage("Your card is expired", "w");
    } else {
      if (isCardSwiped && cardDetails && cardDetails.cardNumber) {
        this.focusCVVField();
        this.setState({ cardDetails, isCardSwiped, passInfo });
      }
    }
  };
  checkCardExpiry = (expiration) => {
    let isCardExpired = false;
    if (expiration) {
      const currentDate = new Date();
      const [month, year] = expiration.split("/");
      const expirationDate = new Date(
        2000 + parseInt(year),
        parseInt(month) - 1
      );
      isCardExpired = expirationDate < currentDate;
    }
    return isCardExpired;
  };
  getQueryParams = () => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams) {
        let kioskValue = queryParams.get("kiosk");
        if (kioskValue && kioskValue.toString().toLowerCase() === "true") {
          this.checkAndGetKioskSetting();
        }
      }
    } else {
      this.checkAndRedirectToMainPage();
    }
  };
  checkAndGetKioskSetting = () => {
    let { kioskSetting } = this.state;
    if (kioskSetting) {
      kioskSetting["IsKioskActive"] = true;
      kioskSetting["RedirectUrl"] = `/?kiosk=true`;
      this.SaveKIOSKInfoIntoStorage(kioskSetting);
    } else {
      this.getSettingInfo("kiosksetting");
    }
  };
  getSettingInfo = (settingName) => {
    let url = `${getSettingInfo}${settingName}`;
    get(url)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          let kioskSetting = data;
          if (kioskSetting) {
            kioskSetting["IsKioskActive"] = true;
            kioskSetting["RedirectUrl"] = `/?kiosk=true`;
            this.SaveKIOSKInfoIntoStorage(kioskSetting);
          }
        }
      });
  };
  SaveKIOSKInfoIntoStorage = (kioskSetting) => {
    let passInfo = this.checkAndGetPassInfo();
    this.setState({ kioskSetting, passInfo }, () => {
      this.checkAndRedirectToMainPage();
    });
    _storage.setItem(KIOSKSetting, JSON.stringify(kioskSetting));
  };
  checkAndGetPassInfo = () => {
    let passInfo = this.getPassInfo();
    return passInfo;
  };
  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  getRestaurantTableNumbers = () => {
    const restaurantInfo = storage.getRestaurantInfo();
    get(
      getRestaurantTableNumbers +
      "&restaurantNodeId=" +
      restaurantInfo.nodeId.toString()
    )
      .then((result) => result.data)
      .then((data) => {
        this.setState({ tableNumbers: data });
      });
  };

  getPickupTime = () => {
    const restaurantInfo = storage.getRestaurantInfo();
    this.setState({ restaurantLocation: restaurantInfo.restaurantLocation });
    get(getPickupTime + "&restaurantNodeId=" + restaurantInfo.nodeId.toString())
      .then((result) => result.data)
      .then((response) => {
        if (response && response.status) {
          let data = JSON.parse(response.data);
          this.setState({
            pickupTime: data.RestaurantPickupTime,
            isShowRestaurantWaitingTime: data.IsShowRestaurantWaitingTime,
          });
        }
      });
  };

  componentDidMount() {
    storage.updateCartItemLastUpdatedOn();
    this.cartExpiryTimeOut = setInterval(
      this.checkCartExpiryTimeOut,
      ExpiryCheck_IntervalMinutes * 60 * 1000
    );
    this.getPickupTime();
    this.getRestaurantTableNumbers();
    this.getGiftCardRanges();
    this.getSMSConditionInfo();
    this.getQueryParams();
    this.checkAndSetEmailField();
    this.checkAndSetTheTableNumber();
    // this.checkAndGetGiftCardsBasesOnGuest();
    this.checkEmailFieldIdDisable();
    this.checkAndGetCardDetails();
    // this.checkAndGetCardIdByGuestNumber();
  }
  checkAndGetCardDetails = () => {
    let orderDetails = JSON.parse(_storage.getItem(OrderDetails));
    if (orderDetails && orderDetails.CardInfo) {
      let cardInfo = orderDetails.CardInfo;
      let url = `${getCardDetailsByCardId}${cardInfo}`;
      get(url)
        .then((result) => result.data)
        .then((data) => {
          if (data) {
            this.setState(
              {
                cardDetails: data,
                cardId: cardInfo,
                isPaymentInfoShowing: true,
                isCardInfoIsMasked: true,
              },
              () => {
                this.handleSubmitButtonDisabled();
                this.checkAndGetGiftCardsBasesOnGuest();
              }
            );
          } else this.checkAndGetGiftCardsBasesOnGuest();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.checkAndGetGiftCardsBasesOnGuest();
    }
  };
  checkEmailFieldIdDisable = () => {
    let { passInfo } = this.state;
    let isEmailIdDisable = passInfo.isPassApplied && passInfo.emailId;
    this.setState({ isEmailIdDisable });
  };
  checkAndGetCardIdByGuestNumber = () => {
    let { passInfo, cardOnFile, guestGiftCards } = this.state;
    let allowCardOnFile = window.ENV.AllowCreditCardOnFile;
    if (allowCardOnFile && passInfo?.isPassApplied) {
      let url = `${getCardIdFromGuestNumber}${passInfo.guestNumber}`;
      get(url)
        .then((result) => result.data)
        .then((data) => {
          if (data) {
            cardOnFile.isShowCardOnFile = true;
            cardOnFile.isCardOnFileSelected =
              guestGiftCards && guestGiftCards.length > 0 ? false : true;
            cardOnFile.cardId = data;
            this.setState({ cardOnFile }, () => {
              this.handleSubmitButtonDisabled();
            });
          }
        })
        .catch((error) => {
          this.showSpinner(false);
          this.showToasterMessage(
            "Some error occured. Please try again .",
            "w"
          );
        });
    }
  };
  checkAndGetGiftCardsBasesOnGuest = () => {
    let { passInfo, cardId } = this.state;
    let allowGiftCardOnFile = window.ENV.AllowGiftCardOnFile;
    let orderAgain = OrderAgainNumber
      ? JSON.parse(_storage.getItem(OrderAgainNumber))
      : "";
    if (passInfo?.isPassApplied && allowGiftCardOnFile) {
      let url = `${getGiftCardsByGuestNumber}${passInfo.guestNumber}`;
      get(url)
        .then((result) => result.data)
        .then((data) => {
          this.setState({ guestGiftCards: data }, () => {
            this.checkAndGetCardIdByGuestNumber();
            if (
              this.state.guestGiftCards &&
              this.state.guestGiftCards.length > 0 &&
              !cardId
            )
              this.handleSelectGiftCard(true, this.state.guestGiftCards[0]);
          });
        })
        .catch((error) => {
          this.showSpinner(false);
          this.showToasterMessage("Some error occured. Please try again.", "w");
        });
    }
  };
  checkAndSetTheTableNumber = () => {
    let tableNumber = JSON.parse(_storage.getItem(TableNumber));
    if (tableNumber)
      this.setState({
        tableNumber: parseInt(tableNumber),
        deliverToTable: tableNumber ? true : false,
      });
  };
  getGiftCardRanges = () => {
    get(getGiftCardRanges)
      .then((result) => result.data)
      .then((data) => {
        if (data && data.length > 0) {
          this.setState({ giftCardRanges: data });
        }
      })
      .catch((error) => {
        this.showSpinner(false);
        this.showToasterMessage("Some error occured. Please try again.", "w");
      });
  };
  checkAndSetEmailField = () => {
    let { kioskSetting, isEmailOptionalInKiosk } = this.state;
    isEmailOptionalInKiosk =
      kioskSetting &&
      kioskSetting.IsKioskActive &&
      window.ENV.IsEmailOptionalInKiosk;
    this.setState({ isEmailOptionalInKiosk });
  };
  checkAndShowPaymentInfo = () => {
    let { kioskSetting } = this.state;
    this.setState({ isPaymentInfoShowing: kioskSetting.IsKioskActive });
  };
  getSMSConditionInfo = () => {
    let smsConditionInfo = storage.getSMSInfo();
    if (smsConditionInfo)
      this.setState({
        smsConditionInfo: smsConditionInfo ? smsConditionInfo.smsInfo : "",
      });
    else this.getSettingInfo("smssetting");
  };
  getSettingInfo = (settingName) => {
    let url = `${getSettingInfo}${settingName}`;
    get(url)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          this.saveSMSInfoIntoStorage(data);
          this.setState({ smsConditionInfo: data });
        }
      });
  };
  saveSMSInfoIntoStorage = (smsInfoText) => {
    let smsInfo = { smsInfo: smsInfoText };
    _storage.setItem(SMSInfoKey, JSON.stringify(smsInfo));
  };
  componentWillUnmount() {
    clearInterval(this.cartExpiryTimeOut);
    this.state.isRefreshScreen && clearTimeout(this.confirmationTimer);
  }

  showCartRemovalMessage = () => {
    let Modal = {
      ...this.state.Modal,
      visible: true,
      cartRemoval: true,
    };
    this.setState({ Modal });
  };
  expirationModalCloseCallback = (type) => {
    if (this.state.Modal.cartRemoval) {
      storage.reInitCartWhenCartIdInvalid();
    } else {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      this.setState({ Modal });
    }
    if (type === "Expired") {
      let redirectURL = _storage.getRedirectURL();
      this.props.history.push(redirectURL);
    }
  };
  checkCartExpiryTimeOut = () => {
    if (!this.state.Modal.visible) {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      if (storage.checkCartItemsExpiration()) {
        Modal.visible = true;
      }
      this.setState({ Modal });
    }
  };

  getEmptyCardDetails = () => {
    return {
      cardNumber: "",
      cvc: "",
      expiration: "",
      amount: 0,
    };
  };

  getPassInfo = (isEmpty = false) => {
    let passInfo = isEmpty ? "" : _storage.getItem(PassInfoKey);
    if (!passInfo) {
      return (passInfo = {
        firstName: "",
        lastName: "",
        passNumber: "",
        emailId: "",
        phoneNumber: "",
        guestNumber: "",
        isPassApplied: false,
        specialText: "",
        isPassInfoManuallyEntered: false,
      });
    }
    passInfo = JSON.parse(passInfo);
    return passInfo;
  };
  getItemFromStorage = (item) => {
    let cipherText = window.localStorage.getItem(item);
    let returnValue = "";
    if (Required_CryptoSecurity && cipherText) {
      returnValue = cryptoJS.decryption(cipherText);
    } else {
      returnValue = cipherText;
    }
    return returnValue; // checkStorageExpiration(returnValue);
  };

  handleChange(event) {
    let passInfo = { ...this.state.passInfo };
    let cardDetails = { ...this.state.cardDetails };
    switch (event.target.id) {
      case "firstName":
        passInfo.firstName = event.target.value;
        this.setState({ passInfo });
        break;
      case "lastName":
        passInfo.lastName = event.target.value;
        this.setState({ passInfo });
        break;

      case "emailId":
        passInfo.emailId = event.target.value;
        this.setState({ passInfo });
        break;

      case "phoneNumber":
        passInfo.phoneNumber = this.normalizeInput(
          event.target.value,
          this.state.passInfo.phoneNumber
        );
        this.setState({ passInfo });
        break;

      case "cardNumber":
        cardDetails.cardNumber = this.cardNumberFormat(event.target.value);
        // this.checkAndRefreshScreenTime();
        this.setState(
          {
            cardDetails,
          },
          () => {
            this.handleSubmitButtonDisabled();
          }
        );
        break;

      case "expiration":
        cardDetails.expiration = this.exiprationDateAndTimeFormat(
          event.target.value
        );
        // this.checkAndRefreshScreenTime();
        this.setState(
          {
            cardDetails,
          },
          () => {
            this.handleSubmitButtonDisabled();
          }
        );
        break;

      case "cvc":
        cardDetails.cvc = event.target.value;
        // this.checkAndRefreshScreenTime();
        this.setState(
          {
            cardDetails,
          },
          () => {
            this.handleSubmitButtonDisabled();
          }
        );
        break;
      case "tableNumber":
        let tableNo = event.target.value ? parseInt(event.target.value) : 0;
        this.setState({ tableNumber: tableNo });
        break;
      default:
        break;
    }
    this.checkAndRefreshScreenTime();
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      (this.state.restaurantDetails.isReservationTableEnabled &&
        this.state.deliverToTable === null)
    ) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isPaymentInfoShowing: true });
    }
    this.checkAndRefreshScreenTime();
  }
  handleSubmitWithKiosk = (event) => {
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      (this.state.restaurantDetails.isReservationTableEnabled &&
        this.state.deliverToTable === null)
    ) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmitOrderClick();
    }
    this.checkAndRefreshScreenTime();
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.Payment);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = () => {
    let redirectURL = _storage.getRedirectURL();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };
  checkAndRefreshScreenTime = () => {
    let { screenRefreshTime, kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime =
        parseInt(kioskSetting.Payment) > 0 ? parseInt(kioskSetting.Payment) : 0;
      this.setState({ screenRefreshTime });
    }
  };
  validated(event) {
    //console.log(event);
  }

  render() {
    let { kioskSetting, isPaymentInfoShowing, isCardInfoIsMasked } = this.state;
    let balanceDue = this.calculateBalanceDue();
    let usedGiftCardBalance = this.getUsedGiftCardBalance();
    let calculatedTotal = this.calculateTotal();
    return (
      <div id="checkout">
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>
        {this.state.toaster?.length === 1 && (
          <Toaster
            message={this.state.toaster[0].message}
            type={this.state.toaster[0].type}
            id={this.state.toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        {this.state.Modal.visible && (
          <CartExpirationDialog modal={this.state.Modal} />
        )}
        <Header value={this.state} />
        {this.state.cartItems && this.getReviewSection()}
        {this.getPickupDetails()}
        <div className="divider"></div>
        {kioskSetting && kioskSetting.IsKioskActive ? (
          <>
            {!isPaymentInfoShowing &&
              this.getPaymentInformationWithKiosk(
                balanceDue,
                usedGiftCardBalance,
                calculatedTotal
              )}
            <div className="divider"></div>
            {this.getYourDetailsWithKiosk()}
          </>
        ) : (
          <>
            {isPaymentInfoShowing
              ? this.getYourDetailsReadonly()
              : this.getYourDetails()}
            <div className="divider"></div>
            {isCardInfoIsMasked
              ? this.getPaymentInformationReadOnly()
              : this.getPaymentInformation(
                balanceDue,
                usedGiftCardBalance,
                calculatedTotal
              )}
          </>
        )}
        {this.state.itemsExpirationModal.visible && (
          <QuantityInfoDialog
            open={true}
            message={this.state.itemsExpirationModal.message}
            onClose={this.state.itemsExpirationModal.onCloseEventCallBack}
          />
        )}
      </div>
    );
    //}
  }

  toggleIcon = () => {
    this.setState({ faChevronDown: !this.state.faChevronDown });
  };

  getReviewSection() {
    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            onClick={() => this.toggleIcon()}
          >
            {this.state.faChevronDown ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
            Review Order
            <span>
              {this.state.cartItems.foodItems.length} Item
              {this.state.cartItems.foodItems.length > 1 && "s"}
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {this.state.cartItems.foodItems.map((item, key) => {
                return (
                  <>
                    <div key={key} className="detail-section">
                      <div className="heading-section">
                        <p>
                          {item.name} ({item.quantity})
                        </p>
                        <p>{formatPrice(item.actualPrice * item.quantity)}</p>
                      </div>
                      {this.getDiscountContainerForItem(item)}
                      {item.customizations.map((customization, key) => {
                        return (
                          <div className="pl-2">
                            <p>{customization.name.replace("Select a", "")}:</p>
                            <div>
                              {customization.customizationOptions.map(
                                (option, key) => {
                                  return (
                                    <div
                                      className="pl-2 details"
                                      key={option.nodeId}
                                    >
                                      <span>{option.name}</span>
                                      {option.price >= 1 && (
                                        <span className="float-right price">
                                          {formatPrice(
                                            option.actualPrice * item.quantity
                                          )}
                                        </span>
                                      )}
                                      {this.getDiscountContainerForItem(option)}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            {customization.customizationOptions.length ===
                              0 && (
                                <div className="pl-2 details">
                                  <span>{customization.textBoxValue}</span>
                                  <p></p>
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
              <div className="total-section">
                <div>
                  <p>Subtotal</p>
                  <p>{this.calculateSubTotal()}</p>
                </div>
                {this.getDiscountContainerForTotal()}
                <div>
                  <p>Sales Tax</p>
                  <p>{this.calculateSalesTax()}</p>
                </div>
                {this.state.isRestaurantContainServiceCharge && (
                  <div>
                    <p>Service Charge</p>
                    <p>{formatPrice(this.calculateServiceCharge())}</p>
                  </div>
                )}
                <div>
                  <p>Tip Amount</p>
                  <p>{this.getTipAmount()}</p>
                </div>
                <div>
                  <p>Total</p>
                  <p>{formatPrice(this.calculateTotal())}</p>
                </div>
                {/* <div>
                  <p>Pickup Details: Today at 12:50pm(To-Do:Yet to work)</p>
                  <QueueToolTip restaurantDetails={this.state.restaurantDetails}/>
                </div> */}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  reservationEnableRadioSelection = (selectedItem) => {
    if (selectedItem === "deliverToTable") {
      this.setState({ deliverToTable: true });
    } else {
      this.setState({ deliverToTable: false });
    }
    this.checkAndRefreshScreenTime();
  };

  getDiscountContainerForItem = (foodItem) => {
    if (
      foodItem.specialDiscount &&
      foodItem.specialDiscount.hasOwnProperty("discount") &&
      foodItem.specialDiscount.discount > 0
    ) {
      return (
        <div
          className="pl-2 details"
          key={foodItem.nodeId.toString() + "_discount"}
        >
          <span>Discount</span>
          <span className="float-right price">
            {formatPrice(foodItem.specialDiscount.discount)}
          </span>
          <p></p>
        </div>
      );
    } else if (
      foodItem.couponDiscount &&
      foodItem.couponDiscount.hasOwnProperty("discount") &&
      foodItem.couponDiscount.discount > 0
    ) {
      return (
        <div
          className="pl-2 details"
          key={foodItem.nodeId.toString() + "_discount"}
        >
          <span>Discount</span>
          <span className="float-right price">
            {formatPrice(foodItem.couponDiscount.discount)}
          </span>
          <p></p>
        </div>
      );
    } else {
      return null;
    }
  };

  getPickupDetails() {
    return (
      <div className="pickup-details">
        <h2>Pickup Details</h2>
        <p className="readyBy">
          {/* Ready today by 12:50pm(To-Do) */}
          {this.state.isShowRestaurantWaitingTime
            ? this.state.restaurantWaitingTimeMessage
            : this.state.pickupTime &&
            "Ready for pickup " + this.state.pickupTime}
          <QueueToolTip restaurantDetails={this.state.restaurantDetails} />
        </p>
        <p className="readyBy">
          {this.state.restaurantLocation &&
            "Location: " + this.state.restaurantLocation}
        </p>
      </div>
    );
  }

  onChangeSMSEnabled = () => {
    let { isSMSEnabled } = this.state;
    this.setState({ isSMSEnabled: !isSMSEnabled });
  };
  onChangePayAtPickupEnabled = () => {
    let {
      isPayAtPickupEnabled,
      cardDetails,
      giftCardInfo,
      guestGiftCards,
      cardOnFile,
    } = this.state;
    guestGiftCards.map((a) => {
      a.usingBalance = 0;
    });
    giftCardInfo.giftCards = [];
    cardDetails = this.getEmptyCardDetails();
    CardSwipe.disable();
    this.checkAndRefreshScreenTime();
    this.setState({
      isPayAtPickupEnabled: !isPayAtPickupEnabled,
      cardDetails,
      submitOrderButtonDisabled:
        isPayAtPickupEnabled && !cardOnFile.isCardOnFileSelected ? true : false,
      giftCardInfo,
      guestGiftCards,
    });
  };

  getYourDetails() {
    return (
      <Fragment>
        <div className="your-details">
          <Form validated={true} onSubmit={this.handleSubmit}>
            <div>
              <h2>Your Details</h2>
              <Form.Group controlId="firstName">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  value={this.state.passInfo?.firstName}
                  disabled={this.state.passInfo?.isPassApplied}
                  onChange={this.handleChange}
                />
                <p className="required">Required</p>
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  value={this.state.passInfo?.lastName}
                  disabled={this.state.passInfo?.isPassApplied}
                  onChange={this.handleChange}
                />
                <p className="required">Required</p>
              </Form.Group>

              <Form.Group controlId="emailId">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  value={this.state.passInfo?.emailId}
                  disabled={this.state.isEmailIdDisable}
                  onChange={this.handleChange}
                />
                <p className="required">Required</p>
              </Form.Group>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder="Enter phone number"
                  value={this.state.passInfo?.phoneNumber}
                  onChange={this.handleChange}
                />
                <p className="required">Optional</p>
              </Form.Group>

              {this.state.passInfo?.phoneNumber && (
                <Form.Group>
                  <Form.Check
                    value={this.state.isSMSEnabled}
                    name="smsenabled"
                    type="checkbox"
                    id="smsenabled"
                    label="ReceiveSMS  Notifications"
                    className="checkboxLabelColor"
                    onChange={() => this.onChangeSMSEnabled()}
                    checked={this.state.isSMSEnabled}
                  />
                  {this.state.smsConditionInfo && (
                    <td
                      dangerouslySetInnerHTML={{
                        __html: this.state.smsConditionInfo,
                      }}
                    />
                  )}
                </Form.Group>
              )}
              {this.state.restaurantDetails.isReservationTableEnabled && (
                <>
                  <Form.Group>
                    {this.state.restaurantDetails.restaurant_unitid !== 1 && (
                      <Form.Check
                        value={this.state.deliverToTable}
                        name="reservationEanbledRadio"
                        type="radio"
                        id="toGo"
                        label="Carryout, Pickup at Window"
                        className={
                          this.state.deliverToTable === null
                            ? "checkboxRequired"
                            : "radiButtonLabelColor"
                        }
                        onChange={(e) =>
                          this.reservationEnableRadioSelection("toGo")
                        }
                        checked={this.state.deliverToTable === false}
                      />
                    )}
                    {this.state.tableNumbers &&
                      this.state.tableNumbers.length > 0 && (
                        <Form.Check
                          value={this.state.deliverToTable}
                          name="reservationEanbledRadio"
                          type="radio"
                          id="deliverToTable"
                          label="Please deliver to my table"
                          className={
                            this.state.deliverToTable === null
                              ? "checkboxRequired"
                              : "radiButtonLabelColor"
                          }
                          onChange={(e) =>
                            this.reservationEnableRadioSelection(
                              "deliverToTable"
                            )
                          }
                          checked={this.state.deliverToTable === true}
                        />
                      )}
                    <p className="required">Required</p>
                  </Form.Group>

                  {this.state.deliverToTable && (
                    <Form.Group controlId="tableNumber">
                      <Form.Label>Table Number</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        onChange={this.handleChange}
                        select={this.state.tableNumber}
                        required
                      >
                        <option
                          value=""
                          selected={this.state.tableNumber === 0}
                        >
                          Select Table Number
                        </option>
                        {this.state.tableNumbers?.map((key) => {
                          return (
                            <option
                              value={key}
                              selected={this.state.tableNumber === key}
                            >
                              {key}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <p className="required">Required</p>
                    </Form.Group>
                  )}
                </>
              )}
              <Button variant="danger" type="submit" value="Submit">
                Next
              </Button>
            </div>
          </Form>
        </div>
      </Fragment>
    );
  }

  getYourDetailsWithKiosk() {
    let { isEmailOptionalInKiosk } = this.state;
    let classes = this.state.isPaymentInfoShowing ? "" : "disabled";
    return (
      <Fragment>
        <div className={`your-details ${classes}`}>
          <h2>Your Details</h2>
          <Form validated={true} onSubmit={this.handleSubmitWithKiosk}>
            <Form.Group controlId="firstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                value={this.state.passInfo.firstName}
                disabled={this.state.passInfo.isPassApplied}
                onChange={this.handleChange}
              />
              <p className="required">Required</p>
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                value={this.state.passInfo.lastName}
                disabled={this.state.passInfo.isPassApplied}
                onChange={this.handleChange}
              />
              <p className="required">Required</p>
            </Form.Group>
            {isEmailOptionalInKiosk ? (
              <>
                <Form.Group controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="phone"
                    placeholder="Enter phone number"
                    value={this.state.passInfo.phoneNumber}
                    onChange={this.handleChange}
                  />
                  <p className="required">Required</p>
                </Form.Group>
                <Form.Group controlId="emailId">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={this.state.passInfo.emailId}
                    disabled={this.state.isEmailIdDisable}
                    onChange={this.handleChange}
                  />
                  <p className="required">Optional</p>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group controlId="emailId">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter email"
                    value={this.state.passInfo.emailId}
                    disabled={this.state.isEmailIdDisable}
                    onChange={this.handleChange}
                  />
                  <p className="required">Required</p>
                </Form.Group>
                <Form.Group controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="Enter phone number"
                    value={this.state.passInfo.phoneNumber}
                    onChange={this.handleChange}
                  />
                  <p className="required">Optional</p>
                </Form.Group>
              </>
            )}
            {this.state.paymentType === "CardDog" && this.state.cardDetails?.cardNumber &&
              <Form.Group controlId="zipCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="Zip"
                  placeholder="Enter zip code"
                  value={this.state.zipCode}
                  maxLength={5}
                  onChange={this.handleZipCodeChange}
                />
                <p className="required">Optional</p>
              </Form.Group>}
            {this.state.passInfo.phoneNumber && (
              <Form.Group>
                <Form.Check
                  value={this.state.isSMSEnabled}
                  name="smsenabled"
                  type="checkbox"
                  id="smsenabled"
                  label="Receive SMS Notifications"
                  className="checkboxLabelColor"
                  onChange={() => this.onChangeSMSEnabled()}
                  checked={this.state.isSMSEnabled}
                />
                {this.state.smsConditionInfo && (
                  <td
                    dangerouslySetInnerHTML={{
                      __html: this.state.smsConditionInfo,
                    }}
                  />
                )}
              </Form.Group>
            )}
            {this.state.restaurantDetails.isReservationTableEnabled && (
              <>
                <Form.Group>
                  {this.state.restaurantDetails.restaurant_unitid !== 1 && (
                    <Form.Check
                      value={this.state.deliverToTable}
                      name="reservationEanbledRadio"
                      type="radio"
                      id="toGo"
                      label="Carryout, Pickup at Window"
                      className={
                        this.state.deliverToTable === null
                          ? "checkboxRequired"
                          : "radiButtonLabelColor"
                      }
                      onChange={(e) =>
                        this.reservationEnableRadioSelection("toGo")
                      }
                      checked={this.state.deliverToTable === false}
                    />
                  )}
                  {this.state.tableNumbers &&
                    this.state.tableNumbers.length > 0 && (
                      <Form.Check
                        value={this.state.deliverToTable}
                        name="reservationEanbledRadio"
                        type="radio"
                        id="deliverToTable"
                        label="Please deliver to my table"
                        className={
                          this.state.deliverToTable === null
                            ? "checkboxRequired"
                            : "radiButtonLabelColor"
                        }
                        onChange={(e) =>
                          this.reservationEnableRadioSelection("deliverToTable")
                        }
                        checked={this.state.deliverToTable === true}
                      />
                    )}
                  <p className="required">Required</p>
                </Form.Group>

                {this.state.deliverToTable && (
                  <Form.Group controlId="tableNumber">
                    <Form.Label>Table Number</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      onChange={this.handleChange}
                      select={this.state.tableNumber}
                      required
                    >
                      <option value="" selected={this.state.tableNumber === 0}>
                        Select Table Number
                      </option>
                      {this.state.tableNumbers?.map((key) => {
                        return (
                          <option
                            value={key}
                            selected={this.state.tableNumber === key}
                          >
                            {key}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <p className="required">Required</p>
                  </Form.Group>
                )}
              </>
            )}
            <Button type="submit" value="Submit" variant="danger">
              {this.checkOrderNeedsToBeVerify()
                ? "Verify Order"
                : "Submit Order"}
            </Button>
          </Form>
        </div>
      </Fragment>
    );
  }

  getYourDetailsReadonly() {
    return (
      <div className="pickup-details">
        <div className="heading-section">
          <h2>Your Details</h2>
          <p onClick={() => this.editYourDetails()}>Edit</p>
        </div>
        <p>
          {this.state.passInfo.firstName} {this.state.passInfo.lastName}
        </p>
        <p className="mb-0">{this.state.passInfo.emailId}</p>
        <p>{this.state.passInfo.phoneNumber}</p>
      </div>
    );
  }

  editYourDetails() {
    this.setState({ isPaymentInfoShowing: false });
  }

  handleGiftCardInfoDialog = () => {
    let { giftCardInfo, cardDetails, showGiftCardInfo } = this.state;
    giftCardInfo.dialogOpen = !giftCardInfo.dialogOpen;
    showGiftCardInfo = giftCardInfo.dialogOpen;
    CardSwipe.disable();
    this.checkAndRefreshScreenTime();
    cardDetails = this.getEmptyCardDetails();
    this.setState({
      giftCardInfo,
      showGiftCardInfo,
      showCreditCardInfo: false,
      showSwipeCard: false,
      cardDetails,
    });
  };

  handleGiftCards = (cardDetails) => {
    cardDetails.usingBalance = Number(
      this.calculateGiftCardUsingBalance(cardDetails.balance)
    );
    this.handleGiftCardBalance([cardDetails]);
  };

  getGiftCardInformation = (giftCardRequest) => {
    post(getGiftCardInformation, giftCardRequest)
      .then((result) => result.data)
      .then((data) => {
        if (data && !data.isInvalid) {
          let cardDetails = {
            balance: parseFloat(data.balance),
            cardNumber: giftCardRequest.cardNumber,
            pin: giftCardRequest.pin,
            usingBalance: Number(
              this.calculateGiftCardUsingBalance(data.balance)
            ),
          };
          this.handleGiftCardBalance([cardDetails]);
        } else {
          this.showToasterMessage(data.invalidMessage, "w");
        }
      });
  };

  handleGiftCardBalance = (giftCards) => {
    let { giftCardInfo } = this.state;
    giftCardInfo.giftCards = [];
    giftCards.map((card) => {
      let cardInfo = {
        balance: parseFloat(card.balance),
        cardNumber: card.cardNumber,
        pin: card.pin,
        usingBalance: parseFloat(card.usingBalance),
      };
      giftCardInfo.giftCards.push(cardInfo);
    });

    storage.updateGiftCardInfo(giftCards);
    this.handleSubmitButtonDisabled();
  };

  handleSelectGiftCard = (checked, card) => {
    let maximumNumberOfPaymentValue = _storage.getItem(MaximumNumberOfPayment);
    const maximumNumberOfPayment = maximumNumberOfPaymentValue
      ? parseInt(maximumNumberOfPaymentValue)
      : 4;
    let maximumNumberOfPaymentCount = maximumNumberOfPayment - 1;
    let { guestGiftCards, giftCardInfo } = this.state;
    let giftCard = guestGiftCards.find(
      (a) =>
        a.cardNumber?.replaceAll(" ", "") ===
        card.cardNumber?.replaceAll(" ", "")
    );
    if (checked) {
      if (giftCardInfo.giftCards.length < maximumNumberOfPayment) {
        giftCard.usingBalance = Number(
          this.calculateGiftCardUsingBalance(card.balance)
        );
        if (giftCardInfo.giftCards.length === maximumNumberOfPaymentCount) {
          const total = this.getCartItemsTotal();
          const giftCardUsedBalance =
            this.getUsedGiftCardBalance() + giftCard.usingBalance;
          let due = total - giftCardUsedBalance;
          if (due > 0) {
            this.showToasterMessage(
              "A maximum of 4 payment types is permitted and the order still has a balance. Please, unselect a gift card and use a credit card for the last payment type.",
              "w"
            );
          } else {
            giftCardInfo.giftCards.push(giftCard);
            this.handleCardOnFile(false);
          }
        } else {
          giftCardInfo.giftCards.push(giftCard);
          this.handleCardOnFile(false);
        }
      } else {
        this.showToasterMessage(
          "A maximum of 4 payment types is permitted and the order still has a balance. Please, unselect a gift card and use a credit card for the last payment type.",
          "w"
        );
      }
    } else {
      giftCard.usingBalance = 0;
      let index = giftCardInfo.giftCards.findIndex(
        (a) => a.cardNumber === card.cardNumber
      );
      if (index >= 0) giftCardInfo.giftCards.splice(index, 1);
    }
    this.setState({ guestGiftCards, giftCardInfo }, () => {
      if (this.calculateBalanceDue() === 0) {
        let { cardDetails } = this.state;
        cardDetails = this.getEmptyCardDetails();
        this.setState({ cardDetails });
      }
      this.handleSubmitButtonDisabled();
    });
  };

  getGiftCardRow = (card) => {
    let giftCardNumber = card?.cardNumber?.trim();
    return `Gift card ending #${giftCardNumber.substr(
      giftCardNumber?.length - 4
    )} Balance: $${card.balance}`;
  };

  disableGiftCards = (isGiftcardSelected) => {
    let due = this.calculateBalanceDue();
    return !isGiftcardSelected && due === 0;
  };
  editPaymentInfo = () => {
    this.setState(
      {
        isCardInfoIsMasked: false,
        cardDetails: this.getEmptyCardDetails(),
      },
      () => {
        this.handleSubmitButtonDisabled();
      }
    );
  };
  getPaymentInformationReadOnly() {
    let { cardDetails } = this.state;
    let { giftCardInfo, cardOnFile, submitOrderButtonDisabled } = this.state;
    let isUsingGiftOrCardOnFile =
      (giftCardInfo?.giftCards && giftCardInfo?.giftCards.length > 0) ||
      cardOnFile.isCardOnFileSelected;
    let classes = this.state.isPaymentInfoShowing ? "" : "information disabled";
    return (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          this.handleSubmitOrderClick(isUsingGiftOrCardOnFile);
        }}
      >
        <div className={classes}>
          <div className="pickup-details">
            <div className="heading-section">
              <h2>Payment Details</h2>
              <p onClick={this.editPaymentInfo}>Edit</p>
            </div>
            <div>
              <p>
                {"Credit card Number : "} {cardDetails?.cardNumber}
              </p>
              <p>
                {"Expiration : "} {cardDetails?.expiration}
              </p>
              <p>
                {"Cvc : "} {cardDetails?.cvc}
              </p>
              <p></p>
            </div>
          </div>
          <div className="payment-information">
            <Button
              disabled={submitOrderButtonDisabled}
              variant="danger"
              type="submit"
            >
              {this.checkOrderNeedsToBeVerify()
                ? "Verify Order"
                : "Submit Order"}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
  getPaymentInformation(balanceDue, usedGiftCardBalance, calculatedTotal) {
    let classes = this.state.isPaymentInfoShowing
      ? "payment-information"
      : "payment-information disabled";
    return (
      <div className={classes}>
        <h2>Payment Information</h2>
        <div>
          <div className="total-summary">
            <p>Total Due</p>
            <p>{formatPrice(calculatedTotal)}</p>
          </div>{" "}
          {this.state.giftCardInfo.dialogOpen && (
            <GiftCardDialog
              open
              onClose={this.handleGiftCardInfoDialog}
              handleGiftCardBalance={this.handleGiftCards}
              handleScreenRefreshTime={this.checkAndRefreshScreenTime}
            />
          )}
          {usedGiftCardBalance > 0 && (
            <>
              <div className="total-summary">
                <p>Gift Card Payment</p>
                {usedGiftCardBalance >= calculatedTotal ? (
                  <p>{formatPrice(calculatedTotal)}</p>
                ) : (
                  <p>{formatPrice(usedGiftCardBalance)}</p>
                )}
              </div>
              <div className="total-summary">
                <p>Remaining Balance Due</p>
                <p>{formatPrice(balanceDue)}</p>
              </div>
            </>
          )}
          {this.state.restaurantDetails.isRestaurantPayAtPickup && (
            <>
              <Form.Check
                style={{ marginLeft: 30 }}
                name="radiButtonLabelPayAtPickup"
                type="checkbox"
                label="Pay at pick up"
                className="radiButtonLabelPayAtPickup"
                onChange={() => this.onChangePayAtPickupEnabled()}
                checked={this.state.isPayAtPickupEnabled}
              />
              {!this.state.isPayAtPickupEnabled && (
                <div className="or">
                  <div>OR</div>
                </div>
              )}
            </>
          )}
          {!this.state.isPayAtPickupEnabled ? (
            this.state.guestGiftCards &&
              this.state.guestGiftCards.length > 0 &&
              this.state.passInfo.isPassApplied ? (
              <div>
                <h2>Use my gift card(s)</h2>
                <div className="gift">
                  {this.state.guestGiftCards &&
                    this.state.guestGiftCards.length > 0 &&
                    this.state.guestGiftCards.map((card, index) => {
                      let isGiftCardSelected =
                        this.state.giftCardInfo?.giftCards?.some(
                          (a) => a.cardNumber === card.cardNumber
                        );
                      return (
                        <ul key={index}>
                          <label>
                            <input
                              type="checkbox"
                              className="giftCards"
                              onChange={(e) =>
                                this.handleSelectGiftCard(
                                  e.target.checked,
                                  card
                                )
                              }
                              checked={isGiftCardSelected}
                              disabled={balanceDue === 0 && !isGiftCardSelected}
                            />
                            {this.getGiftCardRow(card)}
                          </label>
                        </ul>
                      );
                    })}
                </div>
                {!usedGiftCardBalance > 0 && (
                  <Button
                    style={{ marginBottom: 20 }}
                    variant="primary"
                    type="button"
                    onClick={() => this.handleGiftCardInfoDialog()}
                  >
                    Enter a new gift card
                  </Button>
                )}
              </div>
            ) : (
              <>
                <div
                  className="d-flex gift-card"
                  onClick={() => this.handleGiftCardInfoDialog()}
                >
                  <p>Use a Gift Card</p>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </>
            )
          ) : null}
          {this.getCreditCardForm()}
        </div>
      </div>
    );
  }
  kioskYourDetails() {
    this.setState({ isPaymentInfoShowing: false });
  }
  getPaymentInformationWithKiosk(
    balanceDue,
    usedGiftCardBalance,
    calculatedTotal
  ) {
    return (
      <div className="payment-information">
        <h2>Payment Information</h2>
        <div className="total-summary">
          <p>Total Due</p>
          <p>{formatPrice(calculatedTotal)}</p>
        </div>
        {usedGiftCardBalance > 0 && (
          <>
            <div className="total-summary">
              <p>Gift Card Payment </p>
              {usedGiftCardBalance >= calculatedTotal ? (
                <p>{formatPrice(calculatedTotal)}</p>
              ) : (
                <p>{formatPrice(usedGiftCardBalance)}</p>
              )}
            </div>
            <div className="total-summary">
              <p>Remaining Balance Due</p>
              <p>{formatPrice(balanceDue)}</p>
            </div>
          </>
        )}

        {this.state.passInfo.isPassApplied ? (
          <div>
            <h2>Use my gift card(s)</h2>
            <div className="gift">
              {this.state.guestGiftCards &&
                this.state.guestGiftCards.length > 0 &&
                this.state.guestGiftCards.map((card, index) => {
                  let isGiftCardSelected =
                    this.state.giftCardInfo?.giftCards?.some(
                      (a) => a.cardNumber === card.cardNumber
                    );
                  return (
                    <ul key={index}>
                      <label>
                        <input
                          type="checkbox"
                          className="giftCards"
                          onChange={(e) =>
                            this.handleSelectGiftCard(e.target.checked, card)
                          }
                          checked={isGiftCardSelected}
                          disabled={balanceDue === 0 && !isGiftCardSelected}
                        />
                        {this.getGiftCardRow(card)}
                      </label>
                    </ul>
                  );
                })}
            </div>
          </div>
        ) : null}

        {this.state.cardOnFile.isShowCardOnFile && this.getCardOnFile()}

        {this.state.restaurantDetails.isRestaurantPayAtPickup && (
          <>
            <Form.Check
              style={{ marginLeft: 30 }}
              name="radiButtonLabelPayAtPickup"
              type="checkbox"
              label="Pay at pick up"
              className="radiButtonLabelPayAtPickup"
              onChange={() => this.onChangePayAtPickupEnabled()}
              checked={this.state.isPayAtPickupEnabled}
            />
            {!this.state.isPayAtPickupEnabled && (
              <div className="or">
                <div>OR</div>
              </div>
            )}
          </>
        )}
        {!this.state.isPayAtPickupEnabled
          ? !this.state.isCardSwiped &&
          !this.state.cardOnFile.isCardOnFileSelected &&
          balanceDue > 0 && (
            <>
              {" "}
              <div
                style={{
                  backgroundColor: this.state.showSwipeCard
                    ? "darkorange"
                    : "rgb(63, 187, 236)",
                }}
                className="d-flex gift-card"
                onClick={() => this.handleShowSwipeCard()}
              >
                {usedGiftCardBalance === 0 ? (
                  <p>Swipe a credit cart or gift card</p>
                ) : (
                  <p>Swipe a credit cart or gift card</p>
                )}
              </div>
              {this.state.showSwipeCard && (
                <Form.Group controlId="swipe" className="swipe-card">
                  <Form.Label>Swipe the card</Form.Label>
                </Form.Group>
              )}
              <div
                style={{
                  backgroundColor: this.state.showCreditCardInfo
                    ? "darkorange"
                    : "rgb(63, 187, 236)",
                }}
                className="d-flex gift-card"
                onClick={() => this.handleShowcreditCardInfo()}
              >
                <p>Manually enter a credit card</p>
                {/* <FontAwesomeIcon icon={faChevronRight} /> */}
              </div>
              {this.state.showCreditCardInfo && balanceDue > 0 && (
                <>
                  <Form.Group controlId="cardNumber">
                    <Form.Label>Credit Card Numbers</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="000-000-000-0000"
                      value={this.state.cardDetails.cardNumber}
                      onChange={this.handleChange}
                    />
                    {this.state.cardDetails.cardNumber.length >= 1 &&
                      this.state.cardDetails.cardNumber.length < 14 && (
                        <>
                          <div className="text-right required text-danger">
                            Please enter valid card Number
                          </div>
                        </>
                      )}
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} xs="6" controlId="expiration">
                      <Form.Label>Expiration</Form.Label>
                      <Form.Control
                        maxLength="5"
                        type="text"
                        placeholder="MM/YY"
                        value={this.state.cardDetails.expiration || ""}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs="6" controlId="cvc">
                      <Form.Label>CVC</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="000"
                        maxLength="4"
                        value={this.state.cardDetails.cvc}
                        onChange={this.handleChange}
                      />
                      {this.state.cardDetails.cvc.length >= 1 &&
                        this.state.cardDetails.cvc.length < 3 && (
                          <>
                            <div className="text-right required text-danger">
                              Please enter valid CVC
                            </div>
                          </>
                        )}
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              {usedGiftCardBalance === 0 && (
                <div
                  style={{
                    backgroundColor: this.state.showGiftCardInfo
                      ? "darkorange"
                      : "rgb(63, 187, 236)",
                  }}
                  className="d-flex gift-card"
                  onClick={() => this.handleGiftCardInfoDialog()}
                >
                  <p>Manually enter a gift card</p>
                  {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                </div>
              )}
            </>
          )
          : null}
        {this.state.giftCardInfo.dialogOpen && (
          <GiftCardDialog
            open={this.state.giftCardInfo.dialogOpen}
            onClose={() => this.handleGiftCardInfoDialog()}
            handleGiftCardBalance={this.handleGiftCards}
            handleScreenRefreshTime={this.checkAndRefreshScreenTime}
          />
        )}
        {this.getCreditCardFormWithKiosk()}
      </div>
    );
  }
  handleNextButtonWithKiosk = () => {
    let { cardOnFile, isPayAtPickupEnabled } = this.state;
    let disabled = false;
    if (this.calculateBalanceDue() === 0) {
      disabled = false;
    } else if (cardOnFile.isCardOnFileSelected) {
      disabled = false;
    } else {
      if (
        this.state.cardDetails.cardNumber &&
        this.state.cardDetails.expiration &&
        this.state.cardDetails.cvc
      ) {
        if (
          this.state.cardDetails.cardNumber.length >= 1 &&
          this.state.cardDetails.cardNumber.length < 14
        ) {
          disabled = true;
        } else if (
          this.state.cardDetails.cvc.length >= 1 &&
          this.state.cardDetails.cvc.length < 3
        ) {
          disabled = true;
        } else if (this.state.cardDetails.expiration.length < 5) {
          disabled = true;
        } else {
          disabled = false;
        }
      } else {
        disabled = true;
      }
    }
    this.setState({
      isPaymentInfoShowing: isPayAtPickupEnabled ? true : !disabled,
    });
  };
  checkNextButtonIsDisableOrNotInKiosk = () => {
    let disabled = false;
    let { cardOnFile } = this.state;
    if (this.calculateBalanceDue() === 0) {
      disabled = false;
    } else {
      if (cardOnFile.isCardOnFileSelected) {
        disabled = false;
      } else if (
        this.state.cardDetails.cardNumber &&
        this.state.cardDetails.expiration &&
        this.state.cardDetails.cvc
      ) {
        if (
          this.state.cardDetails.cardNumber.length >= 1 &&
          this.state.cardDetails.cardNumber.length < 14
        ) {
          disabled = true;
        } else if (
          this.state.cardDetails.cvc.length >= 1 &&
          this.state.cardDetails.cvc.length < 3
        ) {
          disabled = true;
        } else if (this.state.cardDetails.expiration.length < 5) {
          disabled = true;
        } else {
          disabled = false;
        }
      } else {
        disabled = true;
      }
    }
    return this.state.isPayAtPickupEnabled ? false : disabled;
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };

  handleSubmitOrderClick = (isGiftcardOrCardOnFileUsed) => {
    let serviceChargeInfo = {
      serviceChargeName: this.state.serviceChargeName,
      serviceChargeValue: this.calculateServiceCharge(),
    };
    storage.updateCartItemLastUpdatedOn();
    let { giftCardInfo } = this.state;
    storage.updateGiftCardInfo(giftCardInfo.giftCards);
    let foodItems = [];
    let storedItems = storage.getAllFoodItems();
    let storedfoodItems = storedItems.foodItems;
    storedfoodItems.forEach((foodItem) => {
      let customizations = foodItem.customizations;
      delete foodItem.customizations;
      foodItems.push({ customizations, foodItem });
    });
    storedItems.foodItems = foodItems;
    storedItems.passInfo = this.state.passInfo;
    if (this.state.paymentType === "CardDog") {
      storedItems.zipCode = this.state.zipCode == "" ? window.ENV.ZipCode : this.state.zipCode;
    }
    storedItems.cardDetails = this.state.cardDetails;
    storedItems.specialRequest = this.state.specialRequestValue;
    storedItems.isSpecialRequest = this.state.isShowSpecialRequest;
    if (this.state.cardOnFile?.isCardOnFileSelected) {
      storedItems["cardOnFile"] = this.state.cardOnFile?.cardId?.toString();
    }
    storedItems.IspayAtPickup = this.state.isPayAtPickupEnabled;
    storedItems.tipAmount =
      storedItems.tipAmount !== undefined && storedItems.tipAmount !== ""
        ? parseFloat(storedItems.tipAmount)
        : 0;
    storedItems.cardDetails.amount = Number(
      roundedGiftCardAmount(this.calculateBalanceDue())
    );
    storedItems.restaurantInfo = this.state.restaurantDetails;
    storedItems.rootName = ItemTreeRoot;
    storedItems.tableNumber = this.state.tableNumber
      ? this.state.tableNumber.toString()
      : "";
    //update the modified passinfo
    this.setPassInfo();
    storedItems["IsSMSEnabled"] = this.state.passInfo.phoneNumber
      ? this.state.isSMSEnabled
      : false;
    if (this.state.isRestaurantContainServiceCharge) {
      storedItems["serviceChargeInfo"] = serviceChargeInfo;
    }
    storedItems.isCardInfoIsMasked = this.state.isCardInfoIsMasked;
    storedItems.cardId = this.state.cardId;
    this.showSpinner(true);

    get(token)
      .then((result) => result.data)
      .then((res) => {
        storedItems.salesItem = cryptoJS.encryptionWithCBCMode(
          JSON.stringify(storedItems)
        );

        let isVerifyOrder = this.checkOrderNeedsToBeVerify();
        if (isVerifyOrder) {
          // Giving wrong name to misguide the hacker
          post(verifyProcessSale, storedItems, res)
            .then((result) => result.data)
            .then((data) => {
              this.showSpinner(false);
              if (data.status) {
                let { passInfo } = this.state;
                let verifyOrderInfo = {
                  email: passInfo.emailId,
                  phoneNumber: passInfo.phoneNumber,
                  guestNumber: passInfo.guestNumber,
                  verifyOrderNumber: data.data,
                };
                _storage.verifyOrderInfo(verifyOrderInfo);
                this.props.history.push("/verifyOrder");
              } else {
                if (data.message === "cartId_unavailable") {
                  this.showCartRemovalMessage();
                } else if (data.message !== "") {
                  let itemsExpirationModal = this.state.itemsExpirationModal;
                  itemsExpirationModal.visible = true;
                  itemsExpirationModal.message = data.message;
                  this.setState({
                    itemsExpirationModal: itemsExpirationModal,
                  });
                }
              }
            })
            .catch((error) => {
              this.showSpinner(false);
              let statusCode = error?.response?.status?.toString();
              let message =
                statusCode === "429"
                  ? "An error occurred due to volume of orders, please try again."
                  : "An error occurred while placing the order, please try again.";
              this.showToasterMessage(message, "w");
            });
        } else {
          // Giving wrong name to misguide the hacker
          post(processSale, storedItems, res)
            .then((result) => result.data)
            .then((data) => {
              this.showSpinner(false);
              if (data.status) {
                const tempCartItems = _storage.getItem(CartItemKey);
                storage.reInitializeCart();
                _storage.setItem("temp_" + CartItemKey, tempCartItems);
                _storage.setItem(OrderDetails, data.data);
                this.props.history.replace("/orderPlaced");
              } else {
                if (data.message === "cartId_unavailable") {
                  this.showCartRemovalMessage();
                } else if (data.message !== "") {
                  let itemsExpirationModal = this.state.itemsExpirationModal;
                  itemsExpirationModal.visible = true;
                  itemsExpirationModal.message = data.message;
                  this.setState({
                    itemsExpirationModal: itemsExpirationModal,
                  });
                }
              }
            })
            .catch((error) => {
              this.showSpinner(false);
              let statusCode = error?.response?.status?.toString();
              let message =
                statusCode === "429"
                  ? "An error occurred due to volume of orders, please try again."
                  : "An error occurred while placing the order, please try again.";
              this.showToasterMessage(message, "w");
            });
        }
      });
  };

  handleSubmitButtonDisabled = () => {
    let { cardOnFile, isPayAtPickupEnabled } = this.state;
    let disabled = false;
    if (this.calculateBalanceDue() === 0) {
      disabled = false;
    } else if (cardOnFile.isCardOnFileSelected) {
      disabled = false;
    } else {
      if (
        this.state.cardDetails.cardNumber &&
        this.state.cardDetails.expiration &&
        this.state.cardDetails.cvc
      ) {
        if (
          this.state.cardDetails.cardNumber.length >= 1 &&
          this.state.cardDetails.cardNumber.length < 14
        ) {
          disabled = true;
        } else if (
          this.state.cardDetails.cvc.length >= 1 &&
          this.state.cardDetails.cvc.length < 3
        ) {
          disabled = true;
        } else if (this.state.cardDetails.expiration.length < 5) {
          disabled = true;
        } else {
          disabled = false;
        }
      } else disabled = true;
    }
    this.setState({
      submitOrderButtonDisabled: isPayAtPickupEnabled ? true : disabled,
    });
  };

  setPassInfo = () => {
    _storage.setItem(PassInfoKey, JSON.stringify(this.state.passInfo));
  };

  calculateSubTotal = () => {
    const info = storage.info();
    return formatPrice(info.totalPriceWithoutTax);
  };

  calculateSalesTax = () => {
    const info = storage.info();
    return formatPrice(info.totalSalesTax);
  };

  calculateServiceCharge = () => {
    let serviceCharge = 0;
    const info = storage.info();
    serviceCharge = (info.totalPriceWithoutTax * this.state.targetQty) / 100;
    return serviceCharge;
  };

  getTipAmount = () => {
    const info = storage.info();
    return formatPrice(parseFloat(info.tipAmount));
  };

  calculateTotal = () => {
    const info = storage.info();
    if (!info.totalDiscount) {
      info.totalDiscount = 0;
    }
    if (!info.tipAmount) {
      info.tipAmount = 0;
    }
    const total =
      info.totalPriceWithoutTax +
      info.totalSalesTax -
      info.totalDiscount +
      parseFloat(info.tipAmount) +
      (this.state.isRestaurantContainServiceCharge
        ? parseFloat(this.calculateServiceCharge())
        : 0);
    return total;
  };
  handleShowcreditCardInfo = () => {
    let { cardDetails } = this.state;
    CardSwipe.disable();
    cardDetails = this.getEmptyCardDetails();
    this.setState({
      showSwipeCard: false,
      showCreditCardInfo: true,
      showGiftCardInfo: false,
      isCardSwiped: false,
      cardDetails,
    });
  };

  handleShowSwipeCard = () => {
    CardSwipe.enable();
    this.setState({
      showSwipeCard: true,
      showCreditCardInfo: false,
      showGiftCardInfo: false,
      cardDetails: this.getEmptyCardDetails(),
    });
  };
  focusCVVField = () => {
    if (this.cvvInputRef.current) {
      this.cvvInputRef.current.focus();
    }
  };
  getCreditCardFormWithKiosk = () => {
    let { isCardSwiped, isPaymentInfoShowing } = this.state;
    return (
      <Form
        validated={!this.state.cardDetails.cvc.length === 3 ? false : true}
        onSubmit={this.handleNextButtonWithKiosk}
      >
        {this.calculateBalanceDue() > 0 &&
          isCardSwiped &&
          !isPaymentInfoShowing && (
            <Form.Group as={Col} xs="6" controlId="cvc">
              <Form.Label>CVC</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="000"
                maxLength="4"
                ref={this.cvvInputRef}
                name="cvc"
                value={this.state.cardDetails.cvc}
                onChange={this.handleChange}
              />
              <p className="required">Required</p>
              {this.state.cardDetails.cvc.length >= 1 &&
                this.state.cardDetails.cvc.length < 3 && (
                  <div className="text-right required text-danger">
                    Please enter valid CVC
                  </div>
                )}
            </Form.Group>
          )}
        <p className="order-helping-text">
          Please take a moment to ensure your order is correct.
        </p>
        <Button
          disabled={this.checkNextButtonIsDisableOrNotInKiosk()}
          variant="danger"
          type="submit"
        >
          Next
        </Button>
      </Form>
    );
  };
  handleCardOnFile = (isCardOnFileSelected) => {
    let { cardOnFile, cardDetails } = this.state;
    if (isCardOnFileSelected) {
      cardDetails = this.getEmptyCardDetails();
      this.state.giftCardInfo.giftCards.forEach((card) => {
        this.handleSelectGiftCard(false, card);
      });
    }
    cardOnFile.isCardOnFileSelected = isCardOnFileSelected;

    this.setState({ cardOnFile, cardDetails }, () => {
      this.handleSubmitButtonDisabled();
    });
  };

  getCardOnFile = () => {
    let { cardOnFile } = this.state;
    return (
      <div className="gift">
        <Form.Group style={{ marginLeft: 40 }}>
          <Form.Check
            checked={cardOnFile.isCardOnFileSelected}
            name="cardOnFile"
            type="radio"
            id="cardOnFile"
            label={`XXXX-XXXX-XXXX-${cardOnFile.cardId}`}
            className={"radiButtonLabelColors"}
            onChange={(e) => this.handleCardOnFile(true)}
          />
        </Form.Group>
        {cardOnFile.isCardOnFileSelected && (
          <>
            <p
              className="linkText"
              onClick={() => {
                this.handleCardOnFile(false);
              }}
            >
              <div style={{ marginLeft: 70 }}>
                Click here to enter a different card number
              </div>
            </p>
          </>
        )}
      </div>
    );
  };

  getCreditCardForm = () => {
    let { giftCardInfo, cardOnFile, submitOrderButtonDisabled } = this.state;
    let isUsingGiftOrCardOnFile =
      (giftCardInfo?.giftCards && giftCardInfo?.giftCards.length > 0) ||
      cardOnFile.isCardOnFileSelected;
    return (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          this.handleSubmitOrderClick(isUsingGiftOrCardOnFile);
        }}
      >
        {!this.state.isPayAtPickupEnabled
          ? this.calculateBalanceDue() > 0 && (
            <>
              {cardOnFile.isShowCardOnFile && this.getCardOnFile()}
              {!cardOnFile.isCardOnFileSelected && (
                <>
                  <Form.Group controlId="cardNumber">
                    <Form.Label>Credit Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="000-000-000-0000"
                      value={this.state.cardDetails.cardNumber}
                      onChange={this.handleChange}
                    />
                    {this.state.cardDetails.cardNumber.length >= 1 &&
                      this.state.cardDetails.cardNumber.length < 14 && (
                        <>
                          <div className="text-right required text-danger">
                            Please enter valid card Number
                          </div>
                        </>
                      )}
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} xs="6" controlId="expiration">
                      <Form.Label>Expiration</Form.Label>
                      <Form.Control
                        maxLength="5"
                        type="text"
                        placeholder="MM/YY"
                        value={this.state.cardDetails.expiration || ""}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs="6" controlId="cvc">
                      <Form.Label>CVC</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="000"
                        maxLength="4"
                        value={this.state.cardDetails.cvc}
                        onChange={this.handleChange}
                      />
                      {this.state.cardDetails.cvc.length >= 1 &&
                        this.state.cardDetails.cvc.length < 3 && (
                          <>
                            <div className="text-right required text-danger">
                              Please enter valid CVC
                            </div>
                          </>
                        )}
                    </Form.Group>
                  </Form.Row>
                </>
              )}
              {this.state.paymentType === "CardDog" && (!this.state.cardOnFile?.isCardOnFileSelected || this.state.cardDetails?.cardNumber) &&
                <Form.Group controlId="zipCode">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="Zip"
                    placeholder="Enter zipcode"
                    value={this.state.zipCode}
                    maxLength={5}
                    onChange={this.handleZipCodeChange}
                  />
                  <p className="required">Optional</p>
                </Form.Group>}
            </>
          )
          : null}
        <p className="order-helping-text">
          Please take a moment to ensure your order is correct.
        </p>

        <p
          className="specialRequest"
          onClick={() => {
            this.handleSpecialRequest();
          }}
        >
          {this.state.restaurantDetails.isRestaurantContainsSpecialRequest && (
            <div>
              {!this.state.isShowSpecialRequest
                ? "Have a special request for your order? Click here"
                : "Click here hide special request details"}
            </div>
          )}
        </p>
        <p>
          {this.state.restaurantDetails.isRestaurantContainsSpecialRequest &&
            this.state.isShowSpecialRequest && (
              <textarea
                cols="70"
                rows="2"
                maxLength="80"
                placeholder="Enter special request here..."
                onChange={this.handleChangeSpeciaRequest}
              ></textarea>
            )}
        </p>
        <Button
          disabled={submitOrderButtonDisabled}
          variant="danger"
          type="submit"
        >
          {this.checkOrderNeedsToBeVerify() ? "Verify Order" : "Submit Order"}
        </Button>
      </Form>
    );
  };
  handleChangeSpeciaRequest = (event) => {
    this.setState({ specialRequestValue: event.target.value });
  };
  handleZipCodeChange = (event) => {
    const { value } = event.target;
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value.length <= 5) {
      this.setState({ zipCode: value });
    }
  }
  handleSpecialRequest = () => {
    let { isShowSpecialRequest } = this.state;
    this.setState({ isShowSpecialRequest: !isShowSpecialRequest });
  };

  checkOrderNeedsToBeVerify = () => {
    let { guestGiftCards, giftCardInfo, passInfo, cardOnFile } = this.state;
    let isVerifyOrder = false;

    if (passInfo && passInfo.isPassApplied) {
      if (cardOnFile.isCardOnFileSelected) isVerifyOrder = true;
      else {
        if (
          guestGiftCards &&
          guestGiftCards.length > 0 &&
          giftCardInfo &&
          giftCardInfo.giftCards &&
          giftCardInfo.giftCards.length > 0
        ) {
          guestGiftCards.map((g) => {
            if (!isVerifyOrder) {
              let index = giftCardInfo.giftCards.findIndex(
                (a) =>
                  a.cardNumber.replaceAll(" ", "") ===
                  g.cardNumber.replaceAll(" ", "")
              );
              if (index >= 0) isVerifyOrder = true;
            }
          });
        }
      }
    }
    return isVerifyOrder;
  };

  calculateBalanceDue = () => {
    const total = this.getCartItemsTotal();
    const giftCardUsedBalance = this.getUsedGiftCardBalance();
    let due = total - giftCardUsedBalance;
    if (due < 0) {
      due = 0;
    }
    return parseFloat(due.toFixed(2));
  };
  getUsedGiftCardBalance = () => {
    let { guestGiftCards, giftCardInfo } = this.state;
    let balance = 0;
    if (giftCardInfo.giftCards && giftCardInfo.giftCards.length > 0) {
      balance = giftCardInfo.giftCards
        .map((a) => a.usingBalance)
        .reduce((a, b) => a + b, 0);
    }
    return balance;
  };

  calculateGiftCardUsingBalance = (giftCardBalance) => {
    let totalUsingBalance = this.getSelectedGiftCardUsingBalance();
    let total = this.getCartItemsTotal();
    let remainingBalance = parseFloat(total) - totalUsingBalance;
    if (remainingBalance >= giftCardBalance) {
      return roundedGiftCardAmount(giftCardBalance);
    } else {
      return roundedGiftCardAmount(remainingBalance);
    }
  };

  getSelectedGiftCardUsingBalance = () => {
    let { giftCardInfo } = this.state;
    return giftCardInfo?.giftCards.reduce((sum, current) => sum + current.usingBalance, 0);
  }

  getCartItemsTotal = () => {
    const info = storage.info();
    if (!info.totalDiscount) {
      info.totalDiscount = 0;
    }
    if (!info.tipAmount) {
      info.tipAmount = 0;
    }
    const total =
      info.totalPriceWithoutTax +
      info.totalSalesTax -
      info.totalDiscount +
      info.tipAmount +
      (this.state.isRestaurantContainServiceCharge
        ? parseFloat(this.calculateServiceCharge())
        : 0);
    return total.toFixed(2);
  };

  getDiscountContainerForTotal = () => {
    const info = storage.info();
    if (info.totalDiscount > 0) {
      return (
        <div>
          <p>Guest Discount</p>
          <p>
            {"-"}
            {formatPrice(info.totalDiscount)}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}- ${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  cardNumberFormat = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    return currentValue.replace(/\d{4}(?=.)/g, "$&-");
  };

  exiprationDateAndTimeFormat = (value) => {
    if (!value) {
      return value;
    }
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    const currentMoth = moment().month() + 1;

    // if (!previousValue || value.length > previousValue.length) {
    if (cvLength <= 2) {
      if (cvLength === 2 && Number(currentValue) > 12) {
        return "";
      }
      if (currentValue === "00") {
        return "";
      }
      return currentValue;
    } else {
      const enteredYear = currentValue.slice(2, 4);
      const enteredMonth = currentValue.slice(0, 2);
      if (enteredYear.length === 2) {
        const currentYear = moment().year() - 2000;
        // if year is current year
        if (
          Number(enteredYear) === currentYear &&
          (Number(enteredMonth) < currentMoth || Number(enteredMonth) > 12)
        ) {
          return currentValue.slice(0, 2);
        }

        if (Number(enteredYear) < currentYear) {
          return currentValue.slice(0, 2);
        }
      }
      return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}`;
    }
    // }
  };

  itemsExpirationModalCloseCallBack = () => {
    let itemsExpirationModal = this.state.itemsExpirationModal;
    itemsExpirationModal.visible = false;
    itemsExpirationModal.message = "";
    this.setState({
      itemsExpirationModal: itemsExpirationModal,
    });
  };
}

export default Checkout;
